export default {
  "default": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Innovative social app"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu is a revolutionary mobile app enabling you to find new friends and places. Participate in events and enjoy many discounts!"])}
  },
  "shopButton.googleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download on the Google Play"])},
  "shopButton.appleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download on the AppStore"])},
  "imageAlt": {
    "phone": {
      "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Chats list"])},
      "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Coupon"])},
      "coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Coupons list"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Event details "])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Events list"])},
      "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Map with events and places"])},
      "meetingChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Quick meeting chat"])},
      "meetingMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Map with quick meeting participants locations"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - List of users nearby"])},
      "placeCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Coupons available in pub / club"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Place details"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - User profile"])}
    }
  },
  "component": {
    "homeSlide": {
      "home": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App, designed for hanging out with friends and meeting new people. If you are looking for events nearby or want to chat with someone new, Tullu is for you.\n\nDownload it for free and have fun!"])}
      },
      "quickEvents": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Meets"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Meets"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you feel lonely and want to meet people, you can use Tullu's quick meets. Meet with them only with one click of the button! It'll send a notification to every Tullu's user in your area. They'll see your localization on their map. It's that simple!"])}
      },
      "people": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add new people met at the bar or event using Tullu's QR code scanner. You can also simply match with other people to close to your localization using the discover option in the app. Chat with them using Tullu and arrange the next meeting."])}
      },
      "events": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu's main screen helps you find local events and parties by showing them on the map. If you are looking for a party at a specific distance, go to the events page and quickly see all the potential places and main info. Tullu is also a tooll for you to create your own event. You can choose who and how many people can join. You can organize the best open party ever or prepare it for your friend list. If your style is only VIP's, send invitations to the chosen ones."])}
      },
      "partners": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Because Tullu is concentrated on working with different business partners, you will gain access to special events and rewards. Also, you will be able to attend Tullu parties created exclusively for our users."])}
      },
      "coupons": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If u ever felt, it sucks that no one gives discounts for a drink or ticket to your favourite place, say no more. Tullu allows our partners to create them. They will be shown on the coupons page. But be quick, some of them may be available only for a while."])}
      }
    },
    "shopButton": {
      "commingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comming soon"])}
    },
    "scrollDown": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll down"])}
    },
    "goToTop": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To the top"])}
    }
  }
}